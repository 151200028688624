import React, { forwardRef, memo } from 'react';

import { PROJECT_ALIAS_BY_ID } from 'config/constants/projects/constants';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';

import { Item } from '../Item';

import s from './styles.module.css';

export type TopProjectNewsWidgetBasePropsType = {
  projectId: keyof typeof PROJECT_ALIAS_BY_ID;
  index: number;
  isError: boolean;
  clusterId: CardData['id'];
  clusterPublicationTime: CardData['publicationTime'];
  clusterTitle: CardData['title'];
  clusterCommentsCount: CardData['commentsCount'];
  noComments: CardData['noComments'];
  clusterTopic: CardData['topic'];
  clusterUrl: CardData['url'];
  onRefresh: () => void;
  onClick?: () => void;
};

/**
 * Базовый компонент виджета топа новостей проекта.
 * @param props.projectId – id вертикали запрашиваемого топа;
 * @param props.index – индекс отображаемого кластера из топа;
 * @param props.isError – флаг, отображающий, что данные для виджета загружены с ошибкой;
 * @param props.clusterId – id отображаемого в виджете кластера;
 * @param props.clusterTitle – заголовок отображаемого в виджете кластера;
 * @param props.clusterPublicationTime – дата публикации отображаемого в виджете кластера;
 * @param props.clusterCommentsCount – количество комментариев отображаемого в виджете кластера;
 * @param props.noComments - флаг принудительного отключения комментов в кластере;
 * @param props.clusterTopic – рубрика отображаемого в виджете кластера;
 * @param props.clusterUrl – урл отображаемого в виджете кластера;
 * @param props.onRefresh – функция перезагрузки данных виджета;
 * @param props.onClick - функция клика по айтему.
 */
export const TopProjectNewsWidgetCompactBase = memo(
  forwardRef<HTMLDivElement, TopProjectNewsWidgetBasePropsType>(
    (
      {
        projectId,
        index,
        isError,
        clusterId,
        clusterTitle,
        clusterPublicationTime,
        clusterCommentsCount,
        noComments,
        clusterTopic,
        clusterUrl,
        onRefresh,
        onClick,
      },
      ref,
    ) => (
      <FeedWidgetLayout
        styles={s}
        isError={isError}
        onRefresh={onRefresh}
        isVisibleWithoutJS
        top100Value={`top_news_${PROJECT_ALIAS_BY_ID[projectId]}::${index}`}
        ref={ref}
      >
        <Item
          key={clusterId}
          title={clusterTitle}
          publicationTime={clusterPublicationTime}
          count={clusterCommentsCount}
          noComments={noComments}
          topic={clusterTopic}
          url={clusterUrl}
          onClick={onClick}
        />
      </FeedWidgetLayout>
    ),
  ),
);
