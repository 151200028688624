import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'common/redux/runtime/selectors';
import { FONTS, getLoadFont } from 'utils/fonts/getLoadFont';

import s from './styles.module.css';

export type DataTitleType = {
  name: string | undefined;
  title?: string;
};

type CommonTitlePropsType = {
  dataTitle: DataTitleType;
};

/**
 * Заголовок для страницы.
 * @param dataTitle - данные для заголовка.
 */
export const CommonTitle = memo(({ dataTitle }: CommonTitlePropsType) => {
  const isMobile = useSelector(selectIsMobile);

  const titleFont = getLoadFont(FONTS.manropeExtraBold);

  return (
    <div className={cn(s.container, isMobile && s.mobile)}>
      <h1 className={cn(s.title, titleFont)}>{dataTitle.name || ''}</h1>
      {dataTitle.title && <p className={s.text}>{dataTitle.title}</p>}
    </div>
  );
});
