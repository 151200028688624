import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';

import { CardItem } from './components/CardItem';
import { useTopicNews } from './hooks/useTopicNews';

type TopicNewsWidgetPropsType = {
  topicAlias: string;
  excludedIds?: CardData['id'][];
  newsCount?: number;
};

/**
 * Виджет новостей по топику
 * @param props.topicAlias - алиас топика
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.newsCount - кастомное кол-во новостей в виджете
 */
export const TopicNewsWidget = memo(
  ({ topicAlias, excludedIds = [], newsCount }: TopicNewsWidgetPropsType) => {
    const { title, clusters, isError, fetchData } = useTopicNews(
      topicAlias,
      excludedIds,
    );

    const clustersByCount = newsCount ? clusters.slice(0, newsCount) : clusters;

    return (
      <FeedWidgetLayout
        title={title}
        url={`/${topicAlias}/`}
        top100Value={topicAlias}
        isError={isError}
        onRefresh={fetchData}
      >
        {clustersByCount.map(
          (
            {
              id,
              publicationTime,
              url,
              image,
              title: clusterTitle,
              commentsCount,
            },
            index,
          ) => (
            <CardItem
              key={id}
              index={index}
              publicationTime={publicationTime}
              url={url}
              imageUrl={image.url}
              imageS3={image.s3}
              title={clusterTitle}
              commentsCount={commentsCount}
            />
          ),
        )}
      </FeedWidgetLayout>
    );
  },
  (prev, next) =>
    prev.topicAlias === next.topicAlias &&
    isEqual(prev.excludedIds, next.excludedIds) &&
    prev.newsCount === next.newsCount,
);
