import loadable from '@loadable/component';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { PuidsType } from 'config/constants/common';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { DoctorAnswerWidget } from 'desktop/components/DoctorAnswerWidget';
import { HotNewsWidget } from 'desktop/components/HotNewsWidget';
import { OpinionsWidget } from 'desktop/components/OpinionsWidget';
import { PsychAdviceWidget } from 'desktop/components/PsychAdviceWidget';
import { RecipesWidget } from 'desktop/components/RecipesWidget';
import { TopProjectNewsWidgetCompact } from 'desktop/components/TopNewsWidgetCompact';
import { WeekFilmWidget } from 'desktop/components/WeekFilmWidget';

import { WidgetsType } from './typings';

/**
 * Защищаем от загрузки те компоненты, которые не нужны и не понадобятся.
 */
const FinanceWidgets = loadable(() => import('./components/FinanceWidgets'), {
  resolveComponent: (components) => components.FinanceWidgets,
});
const NewsWidgets = loadable(() => import('./components/NewsWidgets'), {
  resolveComponent: (components) => components.NewsWidgets,
});
const SportWidgets = loadable(() => import('./components/SportWidgets'), {
  resolveComponent: (components) => components.SportWidgets,
});
const AutoWidgets = loadable(() => import('./components/AutoWidgets'), {
  resolveComponent: (components) => components.AutoWidgets,
});
const WomanWidgets = loadable(() => import('./components/WomanWidgets'), {
  resolveComponent: (components) => components.WomanWidgets,
});
const DoctorWidgets = loadable(() => import('./components/DoctorWidgets'), {
  resolveComponent: (components) => components.DoctorWidgets,
});
const WeekendWidgets = loadable(() => import('./components/WeekendWidgets'), {
  resolveComponent: (components) => components.WeekendWidgets,
});
const KinoWidgets = loadable(() => import('./components/KinoWidgets'), {
  resolveComponent: (components) => components.KinoWidgets,
});
const TravelWidgets = loadable(() => import('./components/TravelWidgets'), {
  resolveComponent: (components) => components.TravelWidgets,
});

export type WidgetsColumnPropsType = {
  puids: PuidsType;
};

/**
 * Список виджетов для второй колонки
 * @param puids – объект пуидов для рекламы
 */
export const WidgetsColumn = memo(({ puids }: WidgetsColumnPropsType) => {
  const projectId = useSelector(selectProjectId);

  switch (projectId) {
    case PROJECT_IDS.Finance:
      return <FinanceWidgets puids={puids} />;

    case PROJECT_IDS.Sport:
      return <SportWidgets puids={puids} />;

    case PROJECT_IDS.Auto:
      return <AutoWidgets puids={puids} />;

    case PROJECT_IDS.Woman:
      return <WomanWidgets puids={puids} />;

    case PROJECT_IDS.Doctor:
      return <DoctorWidgets puids={puids} />;

    case PROJECT_IDS.Weekend:
      return <WeekendWidgets puids={puids} />;

    case PROJECT_IDS.Kino:
      return <KinoWidgets puids={puids} />;

    case PROJECT_IDS.Travel:
      return <TravelWidgets puids={puids} />;

    case PROJECT_IDS.News:

    // eslint-disable-next-line no-fallthrough
    default:
      return <NewsWidgets puids={puids} />;
  }
});

type WidgetsConfigType = (
  | WidgetsType
  | {
      projectId: PROJECT_IDS;
      index: number;
      rcmBlockType?: RCM_BLOCK_TYPE;
    }
)[];

type RenderWidgetsListType = (
  widgetsConfig: WidgetsConfigType,
) => React.ReactNode[];

/**
 * Функция, которая возвращает массив виджетов, собранный через конфиг.
 * @param widgetsConfig - конфиг для рендера виджетов.
 */
export const renderWidgetsList: RenderWidgetsListType = (widgetsConfig) =>
  widgetsConfig.map((config) => {
    if (config === WidgetsType.Hotnews) {
      return <HotNewsWidget key="hotNewsWidget" />;
    }

    if (config === WidgetsType.Opinions) {
      return <OpinionsWidget key="opinionsWidget" />;
    }

    if (config === WidgetsType.DoctorAnswer) {
      return <DoctorAnswerWidget key="doctorAnswerWidget" />;
    }

    if (config === WidgetsType.WeekFilm) {
      return <WeekFilmWidget key="weekFilmWidget" />;
    }

    if (config === WidgetsType.PsychAdvice) {
      return <PsychAdviceWidget key="psychAdviceWidget" />;
    }

    if (config === WidgetsType.Recipes) {
      return <RecipesWidget key="recipesWidget" />;
    }

    if ('projectId' in config && 'index' in config) {
      return (
        <TopProjectNewsWidgetCompact
          key={`${config.projectId}_${config.index}`}
          projectId={config.projectId}
          index={config.index}
          rcmBlockType={config.rcmBlockType}
        />
      );
    }

    return null;
  });
