import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTopicsMenu } from 'common/components/TopicsMenu/useTopicsMenu';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';

const REGEXP_FILTER_FOR_FINANCE = /currencies|calculators/;
const PATTERN_FILTER_FOR_TRAVEL = 'guide';

/**
 * Хук, выдающий функцию, выдающую топик по переданному индексу.
 * @param excludedTopicAlias - алиас исключаемого топика.
 * @returns Функция, которая возвращает один из топиков в списке по индексу.
 *  Если индекс больше, чем длина массива с топиками, то отсчет начинается с нуля.
 */
export const useTopicAliasesForWidgets = (
  excludedTopicAlias: TopicType['alias'] = '',
) => {
  const projectId = useSelector(selectProjectId);
  const topics = useTopicsMenu(projectId);

  const topicsForWidget = useMemo(() => {
    const topicsAliases = topics
      .filter((topic) => topic.alias !== excludedTopicAlias)
      .map((topic) => topic.alias);

    if (projectId === PROJECT_IDS.Finance) {
      return topicsAliases.filter(
        (topic) => topic.search(REGEXP_FILTER_FOR_FINANCE) === -1,
      );
    }

    if (projectId === PROJECT_IDS.Travel) {
      return topicsAliases.filter(
        (topic) => !topic.includes(PATTERN_FILTER_FOR_TRAVEL),
      );
    }

    return topicsAliases;
  }, [projectId, topics, excludedTopicAlias]);

  return useCallback(
    (index: number) => topicsForWidget[index % topicsForWidget.length],
    [topicsForWidget],
  );
};
