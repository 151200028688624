import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchVideoForWidget } from 'common/redux/commonData/widgets/videoWidget/asyncs';
import {
  selectWidgetsVideoClusterWithOptionalClusterId,
  selectWidgetsVideoLoadingState,
} from 'common/redux/commonData/widgets/videoWidget/selectors';
import { useAppDispatch } from 'store/hooks';

type UseInitVideoWidgetType = (
  /**
   * id видеокластера, если уже есть. Иначе id будет извлечено самостоятельно.
   */
  clusterId?: CardData['id'] | null,
) => {
  /**
   * Кластер с данными о видео.
   */
  videoCluster: CardData;
  /**
   * Флаг, что данные для виджета в процессе загрузки
   */
  isLoading: boolean;
};

/**
 * Хук для инициализации данных видео-виджета.
 */
export const useInitVideoWidget: UseInitVideoWidgetType = (clusterId) => {
  const dispatch = useAppDispatch();

  const videoCluster = useSelector(
    selectWidgetsVideoClusterWithOptionalClusterId(clusterId),
    shallowEqual,
  );
  const isLoading = useSelector(selectWidgetsVideoLoadingState);

  useEffect(() => {
    if (!videoCluster && !isLoading) {
      dispatch(fetchVideoForWidget());
    }
  }, [dispatch, videoCluster, isLoading]);

  return {
    videoCluster: videoCluster!,
    isLoading,
  };
};
