import React, { useCallback, MutableRefObject, memo, forwardRef } from 'react';

import {
  TopProjectNewsWidgetBasePropsType,
  TopProjectNewsWidgetCompactBase,
} from 'desktop/components/TopNewsWidgetCompact/components/Base';

type RenderWrapperPropsType = {
  recRef: MutableRefObject<HTMLDivElement>;
  recOnClick: () => void;
  clusterId: CardData['id'];
} & TopProjectNewsWidgetBasePropsType;

/**
 * Обертка над обычным виджетом рекомендованной новости, добавляющей отслеживание и отправку событий для рекомендаций.
 * @param props.recRef - реф от обертки рекомендаций;
 * @param props.recOnClick - onClick, передаваемый от обертки рекомендаций;
 * @param props.projectId - id проекта, по которому извлекаются новости;
 * @param props.index - положение карточки в топе.
 */
export const RenderWrapper = memo(
  forwardRef<HTMLDivElement, RenderWrapperPropsType>(
    (
      {
        recRef,
        recOnClick,
        projectId,
        index,
        isError,
        clusterId,
        clusterCommentsCount,
        noComments,
        clusterPublicationTime,
        clusterTopic,
        clusterUrl,
        clusterTitle,
        onRefresh,
        onClick,
      },
      ref,
    ) => {
      const onRef = useCallback(
        (el: HTMLDivElement) => {
          if (recRef) {
            recRef.current = el;
          }

          if (!ref) return;

          if (typeof ref === 'function') {
            ref(el);
          }

          if ('current' in ref) {
            ref.current = el;
          }
        },
        [recRef, ref],
      );

      const unifiedOnClick = useCallback(() => {
        recOnClick?.();
        onClick?.();
      }, [onClick, recOnClick]);

      return (
        <TopProjectNewsWidgetCompactBase
          ref={onRef}
          isError={isError}
          clusterId={clusterId}
          clusterCommentsCount={clusterCommentsCount}
          noComments={noComments}
          clusterPublicationTime={clusterPublicationTime}
          clusterTitle={clusterTitle}
          clusterTopic={clusterTopic}
          projectId={projectId}
          clusterUrl={clusterUrl}
          index={index}
          onClick={unifiedOnClick}
          onRefresh={onRefresh}
        />
      );
    },
  ),
);
