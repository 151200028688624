import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { OkkoWidget } from 'common/components/OkkoWidget';
import { selectOkkoWidgetType } from 'common/redux/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { CardSwitcher } from 'desktop/components/Card';
import { CARD_TYPES } from 'desktop/components/Card/constants';
import { TopicNewsWidget } from 'desktop/components/TopicNewsWidget';

import { WIDGETS, WIDGETS_VALUES } from './constants';
import { LevelContextProvider } from './context';

import s from './styles.module.css';

const NATIVE_INDEX = 4;

const NATIVE_STYLES = { marginBottom: '20px' };

type LevelLayoutPropsType = {
  clusterIds: CardData['id'][];
  topic?: string;
  bannerName: Banners;
  puids: PuidsType;
  widgetName?: WIDGETS_VALUES;
  isCompact?: boolean;
  level: number;
  excludedIds?: CardData['id'][];
  indexOffset: number;
};

/**
 * Шаблон страницы списка новостей.
 * @param clusterIds - id кластеров;
 * @param topic - рубрика, отображаемая в виджете "Последние новости";
 * @param bannerName - название нативного баннера, находящегося среди кластеров;
 * @param puids – рекламные пуиды;
 * @param widgetName - название виджета;
 * @param isCompact - флаг, что отображается компактная версия страницы;
 * @param level - уровень по счету;
 * @param excludedIds - исключенные из выдачи кластера;
 * @param indexOffset - количество индексов, предшевствующих текущему кластеру;
 * @param okkoWidgetType - 1 или 2 в зависимости от того какие константы считали из админки.
 */
export const LevelLayout = memo(
  ({
    clusterIds,
    topic,
    bannerName,
    puids,
    widgetName,
    isCompact = false,
    level,
    excludedIds,
    indexOffset,
  }: LevelLayoutPropsType) => {
    const okkoWidgetType = useSelector(selectOkkoWidgetType);

    const content = clusterIds.map((clusterId, index) => {
      const item = (
        <CardSwitcher
          key={clusterId}
          clusterId={clusterId}
          type={CARD_TYPES.LIST}
          index={index + indexOffset}
        />
      );

      if (index === NATIVE_INDEX) {
        return [
          <Ad
            key={`${clusterId}-${bannerName}`}
            name={bannerName}
            puids={puids}
            className={cn(
              s.ad,
              s.adPlaceholder,
              'banner--native-context--redesign_list',
            )}
            onRenderStyle={NATIVE_STYLES}
          />,
          item,
        ];
      }

      return item;
    });

    return (
      <LevelContextProvider value={level}>
        <div className={s.root}>
          <div className={s.mainBlock}>
            {content}
            {isCompact && !!widgetName && WIDGETS[widgetName]}
          </div>
          {!isCompact && (
            <div>
              <div className={s.middleBlock}>
                {!!topic && (
                  <TopicNewsWidget
                    topicAlias={topic}
                    excludedIds={excludedIds}
                  />
                )}
                {!!widgetName && WIDGETS[widgetName]}
              </div>
            </div>
          )}
          <div className={s.columnWrapper}>
            {level === 1 && okkoWidgetType && (
              <OkkoWidget type={okkoWidgetType} />
            )}

            <Ad
              name={Banners['240x400_2']}
              puids={puids}
              isSticky
              withoutMarginBottom
            />
          </div>
        </div>
      </LevelContextProvider>
    );
  },
  (prev, next) =>
    isEqual(prev.clusterIds, next.clusterIds) &&
    prev.isCompact === next.isCompact,
);
