/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectProjectId,
  selectSberWidgetErid,
} from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';
import { SberWidgetIcon } from 'icons';

import { getSberLink } from './utils';

import s from './styles.module.css';

type SberWidgetPropsType = {
  erid: string;
};

const DEFAULT_DISABLED_ERID_VALUE = '0';

/**
 * Рекламный виджет сбера.
 * @param props.erid - уникальный токен для ссылки СберВиджета.
 */
export const SberWidget = memo(({ erid }: SberWidgetPropsType) => (
  <div className={s.root}>
    <a
      href={getSberLink(erid)}
      className={s.container}
      target="_blank"
      rel="noreferrer"
    >
      <SberWidgetIcon className={s.icon} />
    </a>
    <div className={s.adMeta}>
      <span className={s.title}>Реклама</span>
      <span className={s.content}>Рекламодатель: ПАО Сбербанк</span>
    </div>
  </div>
));

/** Обертка над виджетом, отображающая его лишь на определенных страницах */
export const SberWidgetWrapper = memo(() => {
  const projectId = useSelector(selectProjectId);
  const pageName = useSelector(selectPageName);
  const erid = useSelector(selectSberWidgetErid);

  if (
    projectId !== PROJECT_IDS.Finance ||
    pageName !== PAGE_TYPE.home ||
    erid === DEFAULT_DISABLED_ERID_VALUE
  ) {
    return null;
  }

  return <SberWidget erid={erid} />;
});
