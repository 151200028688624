import cn from 'classnames';
import React, { memo } from 'react';

import { CardLayout } from 'common/components/CardLayout';
import { useCardConfig } from 'common/components/CardLayout/context';
import { RamblerLoader } from 'common/components/RamblerComponents/RamblerLoader';
import { useInitVideoWidget } from 'common/components/VideoWidget/hooks';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { CARD_TYPES } from 'desktop/components/Card/constants';

import { useLevel } from '../LevelLayout/context';

import stylesCard from './stylesCard.module.css';
import stylesList from './stylesList.module.css';

import s from './styles.module.css';

export const STYLES = {
  [CARD_TYPES.VIDEO_LIST]: stylesList as StylesType,
  [CARD_TYPES.VIDEO_CARD]: stylesCard as StylesType,
};

type VideoWidgetPropsType = {
  clusterId: CardData['id'] | null;
  type: keyof typeof STYLES;
  index: number;
};

// TODO(NEWS-0000): Если этот вариант станет основным, подумать над тем, чтобы вынести все компоненты, использующие CardLayout в отдельную папку
/**
 * Карточка видеовиджета.
 * @param clusterId - id отображаемого кластера;
 * @param type - тип отрисовываемой карточки;
 * @param index - индекс кластера в глобальном отношении.
 */
export const VideoWidget = memo(
  ({ clusterId, type, index }: VideoWidgetPropsType) => {
    const { videoCluster, isLoading } = useInitVideoWidget(clusterId);
    const { getTop100 } = useCardConfig();
    const level = useLevel();
    const top100Value = getTop100({ card: videoCluster, type, level, index });
    const top100AttributeWithValue = useTop100AttributeWithValue(top100Value);

    let context = <RamblerLoader className={s.loader} size="medium" />;

    if (!isLoading && videoCluster) {
      const {
        url,
        image: { url: imageUrl },
        title,
        commentsCount,
        topic,
        video: { duration: videoDuration },
        noComments,
      } = videoCluster;

      context = (
        <CardLayout
          clusterUrl={url}
          imageSrc={imageUrl}
          title={title}
          commentsCount={commentsCount}
          style={STYLES[type]}
          topicName={topic}
          videoDuration={videoDuration}
          isLazy
          noComments={noComments}
        />
      );
    }

    return (
      <div
        className={cn(STYLES[type].container, s.root)}
        {...(!!top100Value && top100AttributeWithValue)}
      >
        {context}
      </div>
    );
  },
);
