import React from 'react';

import { CARD_TYPES } from 'desktop/components/Card/constants';
import { VideoWidgetWrapper } from 'desktop/components/VideoWidget';

export enum WIDGETS_VALUES {
  VideoCard = 'VideoCard',
  VideoList = 'VideoList',
  SubscribeWidget = 'SubscribeWidget',
}

export const WIDGETS: { [key: string]: React.ReactElement } = {
  [WIDGETS_VALUES.VideoCard]: (
    <VideoWidgetWrapper
      type={CARD_TYPES.VIDEO_CARD}
      key={WIDGETS_VALUES.VideoCard}
    />
  ),
  [WIDGETS_VALUES.VideoList]: (
    <VideoWidgetWrapper
      type={CARD_TYPES.VIDEO_LIST}
      key={WIDGETS_VALUES.VideoList}
    />
  ),
  // Временно скрыт NEWS-11312
  // [WIDGETS_VALUES.SubscribeWidget]: <SubscribeFormWidget key={WIDGETS_VALUES.SubscribeWidget} />,
};
