import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectTopicByAlias } from 'common/redux/commonData/topics/selectors';
import { fetchTopicNews } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import {
  selectTopicNewsWidgetClusterInCardData,
  selectTopicNewsWidgetErrorState,
} from 'common/redux/commonData/widgets/topicNewsWidget/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { useAppDispatch } from 'store/hooks';

type UseTopicNewsType = (
  topicAlias: string,
  excludedIds?: CardData['id'][],
) => {
  /**
   * Заголовок для виджета
   */
  title: ATTopic['name'];
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета новостей по топику
 * @param topicAlias - алиас топика
 * @param excludedIds - исключенные из выдачи кластера
 */
export const useTopicNews: UseTopicNewsType = (topicAlias, excludedIds) => {
  const dispatch = useAppDispatch();

  const projectId = useSelector(selectProjectId);
  const topic = useSelector(
    selectTopicByAlias(topicAlias, projectId),
    shallowEqual,
  );

  const { id: topicId, name: title = '' } = topic || {};

  const clusters = useSelector(
    selectTopicNewsWidgetClusterInCardData(excludedIds, topicId),
    shallowEqual,
  );
  const error = useSelector(selectTopicNewsWidgetErrorState(topicId));

  const fetchData = useCallback(() => {
    dispatch(fetchTopicNews({ topicId }));
  }, [dispatch, topicId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    title,
    clusters: clusters.filter(Boolean) as CardData[],
    fetchData,
    isError: !!error,
  };
};
