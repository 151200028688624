import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CardLayout, RecLayout } from 'common/components/CardLayout';
import { useCardConfig } from 'common/components/CardLayout/context';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

export type SecondaryCardPropsType = {
  clusterId: CardData['id'] | null;
  index: number;
};

const IMAGE_WIDTH = 220;
const IMAGE_HEIGHT = 170;

/**
 * DESKTOP
 *
 * Карточка второстепенного материала reDesign-версии
 *
 * Является функциональной обёрткой над CardLayout, достаёт кластер по id, формирует пропсы, и передаёт стили.
 * @param clusterId - id кластера, используется для поиска его в entries и последующей отрисовки.
 * @param index - индекс кластера в глобальном отношении.
 */

export const SecondaryCard = memo(
  ({ clusterId, index }: SecondaryCardPropsType) => {
    const cardData = useSelector(selectCardById(clusterId), shallowEqual);

    const {
      title,
      image: cardImage,
      url = '',
      commentsCount,
      publicationTime,
      topic,
      noComments = false,
    } = cardData || {};

    const { url: imageUrl, s3 } = cardImage || {};

    const { getTop100, getRecBlockType } = useCardConfig();

    const imageParams = useMemo(
      () => ({
        s3,
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [clusterId],
    );

    const blockInitAtd = getRecBlockType({ index });
    const formattedDate = publicationTime
      ? datesince({
          pubdate: new Date(publicationTime).getTime() / 1000,
          isShort: true,
        })
      : undefined;
    const top100Value = getTop100({ card: cardData, index });

    if (blockInitAtd) {
      return (
        <RecLayout
          title={title}
          imageSrc={imageUrl}
          imageParams={imageParams}
          clusterUrl={url}
          commentsCount={commentsCount}
          date={formattedDate}
          style={s}
          top100={top100Value}
          clusterId={clusterId}
          position={index}
          rcmKey={blockInitAtd}
          contextItemId={blockInitAtd}
          topicName={topic}
          noComments={noComments}
        />
      );
    }

    return (
      <CardLayout
        date={formattedDate}
        commentsCount={commentsCount}
        topicName={topic}
        clusterUrl={url}
        imageParams={imageParams}
        title={title}
        imageSrc={imageUrl}
        style={s}
        top100={top100Value}
        isSecondaryCard
        noComments={noComments}
      />
    );
  },
);
